import React from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import { MOBILE_APP_URL } from '~/constants/env'
import { matchIsMobileOperatingSystem } from '~/helpers/navigator'
import { useShowModal } from '~/stores/Modal/Modal.provider'
import { trackEvent } from '~/utils/tracking'

type ChildrenProps<T extends React.ReactNode> = {
  appUrl: string | null
  openModal: () => void
  children?: T
}

export type DownloadAppClientProps<T extends React.ReactNode> = {
  children?: T
  render: ({ appUrl, children }: ChildrenProps<T>) => React.ReactNode
}

const DownloadAppContent = <T extends React.ReactNode>({
  render,
  children
}: DownloadAppClientProps<T>) => {
  const isMobileOperatingSystem = matchIsMobileOperatingSystem()
  const currentAppUrl = isMobileOperatingSystem ? MOBILE_APP_URL : null
  const showModal = useShowModal()

  const openModal = () => {
    trackEvent('DOWNLOAD_APP_MODAL_OPEN')
    showModal('downloadApps', {})
  }

  return typeof render === 'function'
    ? render({ appUrl: currentAppUrl, openModal, children })
    : children
}

const DownloadAppClient = <T extends React.ReactNode>({
  children = undefined,
  render
}: DownloadAppClientProps<T>) => {
  return (
    <ClientOnly>
      {() => {
        return (
          <DownloadAppContent render={render}>{children}</DownloadAppContent>
        )
      }}
    </ClientOnly>
  )
}

export default DownloadAppClient
